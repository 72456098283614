import Api from "../utils/Api";

export class DeliveryPeopleService {

  getDecodedPolyline = async () => {
    let response = {}
    try {
      const res = await Api.get(`deliverysessions`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error getting decode d polyline", error);
      response.error = error
      return response
    }
  }
  async getAll(queryParams){
    let response = {}
    try {
      const res = await Api.get(`admin/deliverypeople?${queryParams}`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error getting delivery people", error);
      response.error = error
      return response
    }
  }

  async update(id, payloadUpdate){
    let response = {}
    try {
      const res = await Api.put(`admin/deliverypeople/${id}`, payloadUpdate)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error update delivery man", error);
      response.error = error
      return response
    }
  }

  async updatePassword (id, newPassword) {
    let response = {}
    try {
      const res = await Api.put(`admin/deliverypeople/${id}/password`, {newPassword})
      response.data = res.data
      return response
    } catch (error) {
      console.log("error update delivery man password", error);
      response.error = error
      return response
    }
  }

  async getLastPosition (deliveryManId){
    let response = {}
    try {
      const res = await Api.get(`admin/locationhistories/last-position?deliveryManId=${deliveryManId}`)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error getting delivery man last position", error);
      response.error = error
      return response
    }
  }

  async createLocationHistory (payloadCreate){
    let response = {}
    try {
      const res = await Api.post(`admin/locationhistories`,payloadCreate)
      response.data = res.data
      return response
    } catch (error) {
      console.log("error update delivery man password", error);
      response.error = error
      return response
    }
  }

}