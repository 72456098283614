import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { DeliveryPeopleService } from '../../service/DeliveryPeopleService';
import { Toast } from 'primereact/toast';

const EditDeliveryMan = ({ rowData, lazyLoadData }) => {

    const deliveryPeopleService = new DeliveryPeopleService()
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)
    const toast = useRef(null)

    const initialValues = {
        fullName: rowData.fullName,
        phoneNumber: rowData.phoneNumber,
        active: rowData.active
    }

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required("nom de livreur obligatoire"),
        phoneNumber: Yup.string().required("numero de telephone obligatoire"),
        active: Yup.bool(),
    })


    const onSubmit = async (values, actions) => {
        const res = await deliveryPeopleService.update(rowData._id, values)
        if(res.data){
            lazyLoadData()
            hideDialog()
        } else {
            console.log(res.error)
            toast.current
                .show({
                    severity:'error', 
                    summary: 'Error', 
                    detail:'Erreur de mise à jour du livreur',
                    life: 3000
                });
                
        }
    }
    
    return(
        <>
        <Toast ref={toast} />
        <Button 
        icon="pi pi-pencil"
        className="p-button-sm p-button-rounded p-button-text p-button-warning" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Modifier livreur" modal 
            
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                <div className='mb-3'>
                    {/* FULL NAME */}
                    <p className="mb-2">nom complet</p>
                    <div className="field flex flex-column">
                        <InputText 
                        value={values.fullName}
                        onChange={handleChange('fullName')} 
                        placeholder="nom complet"
                        onBlur={handleBlur('fullName')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('fullName')})} />
                        {getFormErrorMessage('fullName')}
                    </div>

                    <p className="mb-2">numero de telephone</p>
                    <div className="field flex flex-column">
                        <InputText 
                        value={values.phoneNumber}
                        onChange={handleChange('phoneNumber')} 
                        placeholder="numero de telephone"
                        onBlur={handleBlur('phoneNumber')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('phoneNumber')})} />
                        {getFormErrorMessage('phoneNumber')}
                    </div>                


                {/* STATUS */}
                <div className='mb-3'>
                    <p className="mb-2">active</p>
                    <InputSwitch checked={values.active}
                    onChange={handleChange('active')} />
                    {getFormErrorMessage('active')}
                </div>
                
            </div>
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button 
                    label="annuler" 
                    className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' 
                    icon="pi pi-times" 
                    onClick={hideDialog}
                />
                <Button 
                    onClick={handleSubmit} 
                    label={isSubmitting?'loading':'sauvegarder'}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    className='w-auto p-button-sm p-button-success' 
                    icon="pi pi-check"  
                    type='submit'
                />
            </div>
            </>
            )
            }}
            </Formik>
        </Dialog>
        </>
    )
}

export default EditDeliveryMan