import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Cities from "../utils/Ville.json";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate, useNavigation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { customerPagination } from "../atoms/UsersPaginationAtom";
import { toast } from 'react-toastify';

//services
import { TypesService } from "../service/TypesService";
import { CustomersService } from "../service/CustomersService";
import OptionsMenu from "../components/OptionsMenu";
import NoData from "../components/NoData";
import SingleDelete from "../components/SingleDelete";

const Customers = () => {
  const STATUS = [
    { label: "tout", value: null },
    { label: "actif", value: true },
    { label: "inactif", value: false },
  ];
  const typesService = new TypesService();
  const customerService = new CustomersService();
  const [customers, setCustomers] = useState(null);

  const [types, setTypes] = useState([]);
  const [types2, setTypes2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false)
  const dt = useRef(null);
  const navigate = useNavigate();
  const menu = useRef(null);
  const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state

  const [lazyParams, setLazyParams] = useRecoilState(customerPagination);
  const [totalRecords, setTotalRecords] = useState(
    parseInt(lazyParams.totalRecords)
  );

  useEffect(() => {
    lazyLoadData();
  }, [lazyParams]);

  // get all types of customers
  // useEffect(() => {
  //   getTypes()
  // }, []);

  //load customers
  async function lazyLoadData() {
    setLoading(true);
    const response = await customerService.getCustomers(
      lazyParams,
      totalRecords
    );
    if (response.data) {
      setCustomers(response.data.customers);
      console.log("data", response.data.totalDocuments);
      setTotalRecords(response.data.totalDocuments);
      getTypes();
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }

  async function getTypes() {
    setLoading(true);
    const response = await typesService.getCustomersTypes();
    let _types = [];
    _types.push({ label: "Toutes les types", value: null, active: true });
    if (response.data) {
      response.data.map((type) => {
        _types.push({
          label: type.customerType,
          value: type._id,
          active: type.active,
        });
      });
      setTypes(_types);
      setTypes2(
        _types.filter((val, index) => index > 0 && val.active === true)
      );
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }

  // Update the Recoil state when totalRecords changes
  useEffect(() => {
    setLazyParams((prevState) => ({
      ...prevState,
      totalRecords,
    }));
  }, [totalRecords, lazyParams.totalRecords]);

  const onSyncClicked = async () => {
    setLoadingSync(true)
    const response = await customerService.syncCustomers()
    if(response.data){
      setLazyParams({
        first: 0,
        rows: 10,
        page: 1,
        totalRecords: 0,
        filters: {
          customerType: null,
          active: null,
          city: null,
          nameEntreprise: null,
          phoneNumber: null,
          ISE: null,
          customerReference: null,
        },
      })
    } else {
      console.log(response.error)
      toast.error("Erreur réessayez plus tard")
    }
    setLoadingSync(false)
  }


  const statusBodyTemplate = (rowData) => {
    const CustomBadge = (bg, status) => {
      return (
        <div
          style={{ backgroundColor: bg, borderRadius: 10 }}
          className="px-2 w-max"
        >
          <p className="text-sm">{status}</p>
        </div>
      );
    };
    return (
      <>
        {rowData.active ? (
          <>{CustomBadge("#A1D8C1", "Actif")}</>
        ) : (
          <>{CustomBadge("#d8a1a1", "Inactif")}</>
        )}
      </>
    );
  };

  const textTemplate = (rowData, field) => {
    let val = "";
    if (field === "address") val = rowData.address;
    else if (field === "ice") val = rowData.ISE;
    else if (field === "phone") val = rowData.phoneNumber;
    else if (field === "personalName") val = rowData.personalName;
    else if (field === "customerReference") val = rowData.customerReference;
    else val = rowData.nameEntreprise;

    return <p style={{ width: 150 }}>{val}</p>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <OptionsMenu
        rowData={rowData}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
      >
        {rowData._id === toggleMenu && (
          <div className="pb-2 pt-2 options-menu">
            <div
              className="align-items-center flex p-2 pl-3 pr-6 menu-child"
              onClick={() =>
                navigate(`/customers/edit/${rowData?._id}`, {
                  state: { rowData, types2 },
                })
              }
            >
              <i className="pi pi-pencil"></i>
              <span className="uppercase ml-2">modifier</span>
            </div>
            <SingleDelete
              id={rowData?._id}
              name={rowData?.nameEntreprise}
              deleteFunction={() => deleteCustomer(rowData?._id)}
              setToggleMenu={setToggleMenu}
            />
          </div>
        )}
      </OptionsMenu>
    );
  };

  const deleteCustomer = async (_id) => {
    try {
      const response = await customerService.deleteCustomer(_id);
      lazyLoadData();
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeType = (e) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: e.value,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const onChangeCity = (e) => {
    const city = e.value == "Toutes les villes" ? null : e.value;
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        active: lazyParams.filters.active,
        city: city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const onPage = (event) => {
    // setLazyParams({
    //   ...lazyParams,
    //   first: event.first,
    //   rows: 10,
    //   page: event.page + 1,
    // });
    setLazyParams({
      ...lazyParams,
      first: event.first,
      rows: 10,
      page: event.page + 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const cityFilter = (options) => {
    return (
      <Dropdown
        value={lazyParams.filters.city}
        placeholder="villes"
        filter
        optionLabel="ville"
        optionValue="ville"
        options={Cities}
        onChange={onChangeCity}
        style={{ width: 130 }}
        className="p-column-filter mr-2 p-inputtext-sm"
      />
    );
  };

  const typeFilter = (options) => {
    return (
      <Dropdown
        value={lazyParams.filters.customerType}
        placeholder="types"
        options={types}
        style={{ width: 130 }}
        onChange={onChangeType}
        className="p-column-filter mr-2 p-inputtext-sm"
      />
    );
  };

  const iceFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="ice"
        onChange={onIceChanged}
        value={lazyParams.filters.ISE}
      />
    );
  };

  const referenceFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="reference"
        onChange={onReferrenceChanged}
        value={lazyParams.filters.customerReference}
      />
    );
  };

  const nameFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="nom"
        onChange={onNameChanged}
        value={lazyParams.filters.nameEntreprise}
      />
    );
  };

  const phoneFilter = () => {
    return (
      <InputText
        keyfilter={"num"}
        className="mr-2 p-inputtext-sm"
        placeholder="téléphone"
        onChange={onPhoneNumberChanged}
        value={lazyParams.filters.phoneNumber}
      />
    );
  };

  const statusFitler = () => {
    return (
      <Dropdown
        value={lazyParams.filters.active}
        placeholder="Statut"
        options={STATUS}
        onChange={onChangeStatus}
        className="p-column-filter p-inputtext-sm"
      />
    );
  };

  const onIceChanged = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        customerReference: lazyParams.filters.customerReference,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: event.target.value,
      },
    });
  };

  const onReferrenceChanged = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        customerReference: event.target.value,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const onNameChanged = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        customerReference: lazyParams.filters.customerReference,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: event.target.value,
        phoneNumber: lazyParams.filters.phoneNumber,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const onPhoneNumberChanged = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        customerType: lazyParams.filters.customerType,
        customerReference: lazyParams.filters.customerReference,
        active: lazyParams.filters.active,
        city: lazyParams.filters.city,
        nameEntreprise: lazyParams.filters.nameEntreprise,
        phoneNumber: event.target.value,
        ISE: lazyParams.filters.ISE,
      },
    });
  };

  const onChangeStatus = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        selectedCategory: lazyParams.filters.selectedCategory,
        active: event.value,
        reference: lazyParams.filters.reference,
        nameProduct: lazyParams.filters.nameProduct,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="mb-4 flex alignItems-center justify-content-between">
          <div className="flex align-items-center justify-content-center">
            <p className="text-2xl font-semibold">Utilisateurs</p>
          </div>
          <Button 
              icon={loadingSync ? "pi pi-spinner" : "pi pi-sync"}
              label="synchroniser"
              disabled={loadingSync}
              onClick={onSyncClicked}
          />
        </div>

        <div className="card">
          <div className="overflow-x-auto mb-4 flex align-items-center justify-content-start">
            {iceFilter()}
            {referenceFilter()}
            {nameFilter()}
            {phoneFilter()}
            {typeFilter()}
            {cityFilter()}
            {statusFitler()}
          </div>

          <DataTable
            paginator
            lazy
            first={lazyParams.first}
            loading={loading}
            rows={10}
            totalRecords={totalRecords}
            onPage={onPage}
            filterDisplay="row"
            stripedRows
            ref={dt}
            rowHover
            responsiveLayout="scroll"
            value={customers}
            size="small"
            dataKey="_id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Affichée {first} à {last} de {totalRecords} clients"
            emptyMessage={<NoData label="Aucun utilisateur disponible" />}
          >
            {/* <Column
              field="personalName"
              header="nom personel"
              body={(val) => textTemplate(val, "personalName")}
            /> */}
            <Column
              field="phoneNumber"
              header="numéro téléphone"
              body={(val) => textTemplate(val, "phone")}
            ></Column>
            <Column
              field="ISE"
              header="ice"
              body={(val) => textTemplate(val, "ice")}
            ></Column>
            {/* <Column
              field="customerReference"
              header="réference"
              body={(val) => textTemplate(val, "customerReference")}
            /> */}
            <Column
              field="nameEntreprise"
              header="nom d'établissment"
              body={(val) => textTemplate(val, "nameEntreprise")}
            ></Column>

            <Column field="customerType.customerType" header="type"></Column>
            <Column field="city" header="ville"></Column>
            <Column
              field="active"
              header="status"
              body={statusBodyTemplate}
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Customers;
