import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import NoData from "../components/NoData"
//services
import { DeliveryPeopleService } from "../service/DeliveryPeopleService";
import EditDeliveryMan from "../components/deliveryPeople/EditDeliveryMan";
import UpdatePassword from "../components/deliveryPeople/UpdatePassword";
import RealTimeTracking from "../components/deliveryPeople/RealTimeTracking";

const DeliveryPeople = () => {

  const deliveryPeopleService = new DeliveryPeopleService();
  const [deliveryPeople, setDeliveryPeople] = useState({
    records: [],
    count_records: 0,
  });
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 50,
    page: 1,
    filters: {
      fullName: null,
      phoneNumber: null,
      active: null,
      selected_fields: null
    },
    sortfield: "createdAt",
    sortorder: -1
  });

  const statusFilter = [
    { label: "Tous", value: null},
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ]

  useEffect(() => {
    lazyLoadData();
  }, [lazyParams]);

  async function lazyLoadData() {
    setLoading(true);
    const response = await deliveryPeopleService.getAll(generateQueryParams());
    if(response.data) {
      setDeliveryPeople(response.data)
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }
  console.log(deliveryPeople)

  const generateQueryParams = () => {
    let queryParams = `sortfield=${lazyParams.sortfield}&sortorder=${lazyParams.sortorder}&current_page=${lazyParams.page}&total_skip=${lazyParams.first}&limit=${lazyParams.rows}`

    if(deliveryPeople.count_records){
      queryParams+=`&count_records=${deliveryPeople.count_records}`
    }
    if(lazyParams.filters.fullName){
      queryParams+=`&fullName=${lazyParams.filters.fullName}`
    }
    if(lazyParams.filters.phoneNumber){
      queryParams+=`&phoneNumber=${lazyParams.filters.phoneNumber}`
    }
    if(
      typeof lazyParams.filters.active !== "undefined"
      && lazyParams.filters.active!== null
    ){
      queryParams+=`&active=${lazyParams.filters.active}`
    }
    console.log(queryParams)
    return queryParams
  }

  const onPage = (event) => {
    console.log(event)
    setLazyParams({
        ...lazyParams,
        first: event.first,
        rows: event.rows,
        page: event.page + 1
    })
}

  const onSort = (event) => {
    let sortorder;
    if(lazyParams.sortfield == null || lazyParams.sortfield != event.sortField){
      sortorder = -1
    } else {
      sortorder = lazyParams.sortorder * -1
    }
    setLazyParams({
        ...lazyParams,
        first: 0,
        rows: 50,
        page: 1,
        sortfield: event.sortField,
        sortorder: sortorder,
    });
  };

  const nameFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="nom"
        onChange={onFullNameChanged}
        value={lazyParams.filters.fullName}
      />
    );
  };

  const statusFitler = () => {
    return (
      <Dropdown
        value={lazyParams.filters.active}
        placeholder="Statut"
        options={statusFilter}
        onChange={onStatusChanged}
        className="p-column-filter p-inputtext-sm"
      />
    );
  };

  const onStatusChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      filters: {
       ...lazyParams.filters,
        active: event.value
      }
    })
  }

  const onFullNameChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      firs:0,
      page:1,
      page: 1,
      filters: {
       ...lazyParams.filters,
        fullName: event.target.value
      }
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
        <div className="actions" >
          <EditDeliveryMan rowData={rowData} lazyLoadData={lazyLoadData}/>
          <UpdatePassword _id={rowData._id} />
          {/* <LocationHistories /> */}

        </div>
    );
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="mb-4 flex alignItems-center justify-content-between">
            <p className="text-2xl font-semibold">Livreurs</p>
            {/* <LocationHistories /> */}
            {deliveryPeople.records.length > 0 &&
              <RealTimeTracking deliveryPeople={deliveryPeople.records} />
            }
        </div>

        <div className="card">
          <div className="overflow-x-auto mb-4 flex align-items-center justify-content-start">
            {nameFilter()}
            {statusFitler()}
          </div>

          <DataTable
            paginator
            lazy
            first={lazyParams.first}
            loading={loading}
            rows={50}
            totalRecords={deliveryPeople.count_records}
            onPage={onPage}
            filterDisplay="row"
            stripedRows
            ref={dt}
            rowHover
            responsiveLayout="scroll"
            value={deliveryPeople.records}
            size="small"
            dataKey="_id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} à {last} de {totalRecords}"
            emptyMessage={<NoData label="Aucun livreurs disponible" 
          />}
          >
            <Column
              field="fullName"
              header="Nom"
            />
            <Column
              field="phoneNumber"
              header="Téléphone"
            />
            <Column 
              header={"actions"}
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPeople;
