import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
import { ExpeditionsService } from "../../service/ExpeditionsService";
import OrdersByCustomers from "./OrdersByCustomers";
import { Button } from "primereact/button";
const DeliverySessionsDetails = ({ 
    rowData, dialogVisibility, setDialogVisibilty,
    refetchData, setRefetchData
 }) => {

  const expeditionService = new ExpeditionsService()
  const [loading, setLoading] = useState(false)
  const [ordersByCustomers, setOrdersByCustomers] = useState(null)
  const [dialogVisibilyOrders, setDialogVisibiltyOrders] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if(dialogVisibility || refetchData){
      async function getOrdersByCustomers(){
        console.log("get orders by customers")
        setLoading(false)
        const selectedExpeditions = rowData.join(",")
        const response  = await expeditionService.getGrouppedByCustomers(selectedExpeditions)
        if(response && response.data) {
          setOrdersByCustomers(response.data)
        }
        setLoading(false)
      }
      getOrdersByCustomers()
    }
    return () => {
      if(refetchData){
        setRefetchData(false)
      }
    }
    
  },[dialogVisibility, refetchData])


  const onRowClicked = (event) => {
    setSelectedRow(event.data.orders)
    if(refetchData){
      setRefetchData(false)
    }
    setDialogVisibiltyOrders(true)
  }

  const dt = useRef(null);

  const bodyTemplateStatus = (data) => {
    let severity, text
    let isPending = false, isCompleted = false, isPartial = true
    isPending = data.orders.every((order) => {
      return order.status === "pending" ||  order.status === "started"
    })
    if(!isPending){
      isCompleted = data.orders.every((order) => {
        return order.status === "delivered" || 
          order.status === "customer_not_found" ||
          order.status === "canceled"
      })
    }
    if(isCompleted || isPending){
      isPartial = false
    }
    console.log(isPending)
    if(isPending) {
      severity = '#FAAE42'
      text = 'En attente' 
    } else if(isCompleted) {
      severity = '#05B171'
      text = 'complétée'
    } else if(isPartial){
      severity = '#007BFF'
      text = 'partielle'
    }
    return(
      <div 
        style={{ 
          backgroundColor: severity,
          borderRadius:12,
          display:'inline-block'
        }} 
        className='pr-2 pl-2'
      >
        <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{text}</p>
      </div>
    )
  }



  return (
    <Dialog 
      draggable={false} 
      visible={dialogVisibility}
      breakpoints={{'1900px': '60vw', '640px': '100vw'}}
      header="Détails de la session" modal 
      className="p-fluid" 
      onHide={() => setDialogVisibilty(false)}
    >
      {dialogVisibilyOrders &&
      <OrdersByCustomers
        rowData={selectedRow} 
        dialogVisibility={dialogVisibilyOrders} 
        setDialogVisibilty={setDialogVisibiltyOrders}
        setRefetchData={setRefetchData}
      />
      }
      <DataTable
        onRowClick={onRowClicked}
        loading={loading}
        rows={50}
        filterDisplay="row"
        stripedRows
        ref={dt}
        rowHover
        responsiveLayout="scroll"
        value={ordersByCustomers}
        size="small"
        dataKey="_id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} à {last} de {totalRecords}"
        emptyMessage={
        <NoData label="Aucun commande disponible" />
        }
      >
         
        <Column
          field="customerName"
          header="nom client"
        />
        <Column 
          field="total"
          header="Total"
          body={(rowData) => `${rowData.total} DH`}
        />
       
        <Column 
          header="total commandes"
          body={(rowData) => rowData.orders.length}
        />
        <Column
          header="Statut livraison" 
          body={bodyTemplateStatus}
        />

      </DataTable>
    </Dialog>
  )
}

export default DeliverySessionsDetails;