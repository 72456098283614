import React, { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
const ExpeditionDetails = ({rowData, dialogVisibility, setDialogVisibilty}) => {
  console.log(rowData)
  const dt = useRef(null);

  const bodyTemplateStatus = (val) => {
    let severity, text
    if(val.status === "pending") {
      severity = '#FAAE42'
      text = 'En attente' 
    } else if(val.status === "delivered") {
      severity = '#05B171'
      text = 'Livrée'
    } else if(val.status === "started"){
      severity = '#007BFF'
      text = 'Commencé'
    } else if (val.status === "canceled"){
      severity = '#DC3545'
      text = 'Annulée'
    } else if(val.status === "customer_not_found"){
      severity = '#808080'
      text = 'Client non trouvé'
    }
    return(
      <div 
        style={{ 
          backgroundColor: severity,
          borderRadius:12,
          display:'inline-block'
        }} 
        className='pr-2 pl-2'
      >
        <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{text}</p>
      </div>
    )
  }

  return (
    <Dialog 
      draggable={false} 
      visible={dialogVisibility}
      breakpoints={{'1900px': '60vw', '640px': '100vw'}}
      header="Détails de l'expédition" modal 
      className="p-fluid" 
      onHide={() => setDialogVisibilty(false)}
    >
      <DataTable
        rows={20}
        filterDisplay="row"
        stripedRows
        ref={dt}
        rowHover
        responsiveLayout="scroll"
        value={rowData.order_ids}
        size="small"
        dataKey="_id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} à {last} de {totalRecords}"
        emptyMessage={
        <NoData label="Aucun commande disponible" />
        }
      >
        <Column
          field="refBc"
          header="BC"
        />
        <Column 
          field="customerName"
          header="Client"
        />
        <Column 
          field="total"
          header="Total"
        />
        <Column 
          field="status"
          header="Status"
          body={bodyTemplateStatus}
        />

      </DataTable>
    </Dialog>
  )
}

export default ExpeditionDetails;