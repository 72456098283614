import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";


function ConfirmationModal({ 
  visible,
  setVisible,
  onConfim, 
  text,
  loading 
  }){

  const onConfirmClicked = () => {
    onConfim()
  }

  const onCancelClicked = () => {
    setVisible(false)
  }

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Non"
          icon="pi pi-times"
          onClick={onCancelClicked}
          className="p-button-text p-button-sm"
          disabled={loading}
        />
        <Button
          label="Oui"
          icon="pi pi-check"
          className="p-button-sm p-button-success"
          onClick={onConfirmClicked}
          disabled={loading}
          loading={loading}
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Confirmation"
      visible={visible}
      style={{ width: "50vw" }}
      footer={renderFooter}
      onHide={() => setVisible(false)}
    >
      <p>{text}</p>
    </Dialog>
  );
}

export default ConfirmationModal;
