import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const UpdateStatus = ({ rowData, updateStatus }) => {

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const hideDialog = () => setDialogVisibility(false)

    const openDialog = (status) => {
        setSelectedStatus(status)
        setDialogVisibility(true)
    }
    const onConfirmCicked = async () => {
        setLoading(true)
        await updateStatus(rowData._id, selectedStatus)
        setLoading(false)
        hideDialog()
        
    }

    const updateDialogFooter = () => {
        return (
          <>
            <Button label="non" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button 
            loading={loading}
            disabled={loading}
            label="oui" 
            icon="pi pi-check" 
            className="p-button-text p-button-success" 
            onClick={onConfirmCicked}
            />
          </>
        )
    }

    return (
        <>
        <div className='flex'>
            {rowData.status === "EN COURS" &&
            <Button 
                className='p-button-sm p-button-rounded p-button-text'
                icon="pi pi-check"
                style={{
                    color: "green"
                }}
                tooltip='marquer comme livré'
                onClick={() => openDialog("LIVRÉ")}
            />
            }
            {rowData.status !== "ANNULÉE" &&
            <Button 
                className='p-button-sm p-button-rounded p-button-text'
                icon="pi pi-times"
                style={{
                    color: "red"
                }}
                tooltip='annuler la commande'
                tooltipOptions={{  position: 'top'}}
                onClick={() => openDialog("ANNULÉE")}
            />
            }

        </div>
        <Dialog visible={dialogVisibility} style={{ width: '450px' }} header="Confirmer" modal 
        footer={updateDialogFooter} 
        onHide={hideDialog}>
        <div className="flex align-items-center justify-content-center"> 
        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            <div className="flex flex-column align-items-start justify-content-center">
                <span className='mt-2'>
                    Êtes-vous sûr de vouloir marquer ce cadeau commandé comme {
                        <b>{selectedStatus}</b>
                    } ?
                </span>
        </div>
        </div>
        </Dialog>
        </>
    )

}

export default UpdateStatus