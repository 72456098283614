
import { PrimeIcons } from 'primereact/api';


const menuSousAdmin = [
    {
        label: 'Acceuil',
        items: [{
            label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/'
        }]
    },
    {
        label: 'Produits',
        items: [
            { label: 'Catégories', icon: 'pi pi-fw pi-th-large', to: '/categories' },
            { label: 'Marques', icon: 'pi pi-fw pi-filter', to: '/brands' },
            { label: 'Produits', icon: 'pi pi-fw pi-box', to: '/products' }
        ]
    },
    {
        label: 'Commandes',
        items: [
            { label: 'Commandes', icon: 'pi pi-fw pi-money-bill', to: '/orders' }
        ]
    },
    // {
    //     label: 'Livraison',
    //     items: [
    //         { label: 'Livreurs', icon: 'pi pi-fw pi-car', to: '/livreurs' },
    //     ]
    // },
    {
        label: 'Utilisateurs',
        items: [
            { label: 'Utilisateurs', icon: 'pi pi-fw pi-users', to: '/customers' },
            { label: 'Types', icon: 'pi pi-fw pi-sitemap', to: '/types' },
            { label: 'propositions', icon: 'pi pi-fw pi-phone', to: '/proposals' }
        ]
    },
    {
        label: 'paramètres',
        items: [
            { label: 'notification', icon: 'pi pi-bell', to: '/notifications' },
            { label: 'paramètres', icon: 'pi pi-fw pi-cog', to: '/settings' }
        ]
    }
];


const menuSuperAdmin = [
    {
        label: 'Acceuil',
        items: [{
            label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/'
        }]
    },
    {
        label: 'Produits',
        items: [
            { label: 'Catégories', icon: 'pi pi-fw pi-th-large', to: '/categories' },
            { label: 'Marques', icon: 'pi pi-fw pi-filter', to: '/brands' },
            { label: 'Produits', icon: 'pi pi-fw pi-box', to: '/products' },
            { label: "Offres", icon: "pi pi-fw pi-bolt", to: "/offers" },
        ]
    },
    {
        label: 'Fidelisation clients',
        items: [
            { label: 'Cadeaux et points', icon: 'pi pi-fw pi-circle', to: '/gifts' },
            { label: 'Cadeaux commandés', icon: 'pi pi-fw pi-money-bill', to: '/orderedgifts' },
        ]
    },
    {
        label: 'Commandes',
        items: [
            { label: 'Commandes', icon: 'pi pi-fw pi-money-bill', to: '/orders' }
        ]
    },
    {
        label: 'Expeditions',
        items: [
            { label: 'Livreurs', icon: 'pi pi-fw pi-car', to: '/livreurs' },
            { label: 'Expeditions', icon: 'pi pi-fw pi-box', to: '/expeditions' },
            { label: 'Sessions', icon: 'pi pi-clock', to: '/deliverysessions' },
        ]
    },
    {
        label: 'Utilisateurs',
        items: [
            { label: 'Utilisateurs', icon: 'pi pi-fw pi-users', to: '/customers' },
            { label: 'Types', icon: 'pi pi-fw pi-sitemap', to: '/types' },
            { label: 'propositions', icon: 'pi pi-fw pi-phone', to: '/proposals', badge:'3' }
        ]
    },
    {
        label: 'Admin',
        items: [
            { label: 'Admins', icon: 'pi pi-fw pi-users', to: '/admin' },
        ]
    },
    {
        label: 'paramètres',
        items: [
            { label: 'notification', icon: 'pi pi-bell', to: '/notifications' },
            { label: 'paramètres', icon: 'pi pi-fw pi-cog', to: '/settings' }
        ]
    }
];


export const renderSideMenu=(type, badge)=>{
    if(type === 'superAdmin'){
        return menuSuperAdmin.map(menuItem => {
            const updatedItems = menuItem.items.map(item => {
                if (item.label === 'propositions') {
                    return { ...item, badge: badge };
                }
                return item;
            });

            return { ...menuItem, items: updatedItems };
        });
    }else{
        return menuSousAdmin.map(menuItem => {
            const updatedItems = menuItem.items.map(item => {
                if (item.label === 'propositions') {
                    return { ...item, badge: badge };
                }
                return item;
            });

            return { ...menuItem, items: updatedItems };
        });
    }
}