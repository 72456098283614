import { addLocale, locale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import React from "react";

function RangeDateSelect({ selectedRange, setSelectedRange }) {

  addLocale("fr", {
    firstDayOfWeek: 1,
    dayNames: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    monthNamesShort: [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jul",
      "Aoû",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ],
    today: "aujourd'hui",
    clear: "réinitialiser",
  });

  locale("fr");

  return (
    <Calendar
      value={selectedRange}
      onChange={(e) => {
        setSelectedRange(e.value)
      }}
      numberOfMonths={2}
      placeholder="selectionnez la date"
      selectionMode="range"
      showIcon
      className="mr-2 p-inputtext-sm"
      dateFormat="dd/mm/yy"
      showButtonBar
    />
  );
}

export default RangeDateSelect;
