import Api from "../utils/Api";

export class ExpeditionsService {

  getAll = async (queryParams) => {
    let response = {}
    try {
      console.log("query params: ",queryParams)
      const res = await Api.get(`admin/expeditions?${queryParams}`)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  getGrouppedByCustomers = async (expeditions) => {
    let response = {}
    try {
      const res = await Api.get(`admin/expeditions/groupped-by-customers?expeditions=${expeditions}`)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  updateOrdersDeliveriesStatus = async (payload) => {
    let response = {}
    try {
      const res = await Api.put(`admin/expeditions`, payload)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }
  
}