import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
import { ExpeditionsService } from "../../service/ExpeditionsService";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ConfirmationModal from "../ConfirmationModal";
const OrdersByCustomers = ({
  rowData, dialogVisibility, setDialogVisibilty,
  setRefetchData
}) => {

  const expeditionService = new ExpeditionsService()
  const [orders, setOrders] = useState([])
  const dt = useRef(null);
  const toast = useRef(null)
  const [modalConfirmVisibility, setModalConfirmVisibility] = useState(false)
  const [modalConfimProps, setModalConfirmProps] = useState({
    text: null,
    onConfim: null,
    onCancel: null,
  }) 
  const [loading, setLoading] = useState(false)
  
   
  useEffect(() => {
    if(dialogVisibility){
      console.log("useeffect")
      setOrders(rowData.map((order) => ({...order, newStatus: null})))
    }
    return () => {
      if(modalConfirmVisibility){
        setModalConfirmVisibility(false)
      }
    }
  },[dialogVisibility])

  const onStatusPressed = (selectedOrder, status) => {
    setOrders(prevOrders =>
      prevOrders.map(order =>
        order.odooId === selectedOrder.odooId
          ? { ...order, newStatus: order.newStatus === status ? null : status }
          : order
      )
    );
  };

  const handleValidate = () => {
    let disabled = true
    if(orders.find((order) => order.newStatus !== null)){
      disabled = false
    }
    return disabled
  }

  const onValidate = () => {
    setModalConfirmVisibility(true)
    setModalConfirmProps({
      text: "Êtes-vous sûr de vouloir effectuer cette action ? Cette opération est irréversible.",
      onConfim: async () => {
        setLoading(true)
        let payload = []
        for(let order of orders){
          if(order.newStatus){
            payload.push({
              status: order.newStatus,
              expeditionId: order.expeditionId,
              orderOdooId: order.odooId
            })
          }
        }
        await expeditionService.updateOrdersDeliveriesStatus(payload)
        setLoading(false)
        setModalConfirmVisibility(false)
        setDialogVisibilty(false)
        setRefetchData(true)
      },
      onCancel: () => {
        setModalConfirmVisibility(false)
      }
    })
  }

  const onCustomerNotFoundClicked = async () => {
    setModalConfirmVisibility(true)
    setModalConfirmProps({
      text: "Êtes-vous sûr de vouloir effectuer cette action ? Cette opération est irréversible.",
      onConfim: async () => {
        setLoading(true)
        let payload = []
        for(let order of orders){
          payload.push({
            status: "customer_not_found",
            expeditionId: order.expeditionId,
            orderOdooId: order.odooId
          })
        }
        await expeditionService.updateOrdersDeliveriesStatus(payload)
        setLoading(false)
        setModalConfirmVisibility(false)
        setDialogVisibilty(false)
        setRefetchData(true)
      },
      onCancel: () => {
        setModalConfirmVisibility(false)
      }
    })
  }

  const footer = () => {
    return (
      <div className="flex-ends"> 
        <Button 
          className="p-button-sm mr-2" 
          label="client non trouvé"
          onClick={onCustomerNotFoundClicked}
        />
        <Button   
          disabled={handleValidate()}
          className="p-button-sm" 
          label="valider"
          onClick={onValidate}
        />
      </div>
    )
  }

  const bodyTemplateStatus = (val) => {
    let severity, text
    if(val.status === "pending") {
      severity = '#FAAE42'
      text = 'En attente' 
    } else if(val.status === "delivered") {
      severity = '#05B171'
      text = 'Livrée'
    } else if(val.status === "started"){
      severity = '#007BFF'
      text = 'Commencé'
    } else if (val.status === "canceled"){
      severity = '#DC3545'
      text = 'Annulée'
    } else if(val.status === "customer_not_found"){
      severity = '#808080'
      text = 'Client non trouvé'
    }
    return(
      <div 
        style={{ 
          backgroundColor: severity,
          borderRadius:12,
          display:'inline-block'
        }} 
        className='pr-2 pl-2'
      >
        <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{text}</p>
      </div>
    )
  }

  return (
    <>
    <Toast ref={toast} />
    <Dialog 
      footer={footer}
      draggable={false} 
      visible={dialogVisibility}
      breakpoints={{'1900px': '60vw', '640px': '100vw'}}
      header="Commandes" modal 
      className="p-fluid" 
      onHide={() => setDialogVisibilty(false)}
    >
      {modalConfirmVisibility && 
      <ConfirmationModal 
        visible={modalConfirmVisibility}
        setVisible={setModalConfirmVisibility}
        onConfim={modalConfimProps.onConfim}
        onCancel={modalConfimProps.onCancel}
        text={modalConfimProps.text}
        loading={loading}
      />
      }
      <DataTable
        rows={50}
        filterDisplay="row"
        stripedRows
        ref={dt}
        rowHover
        responsiveLayout="scroll"
        value={orders}
        size="small"
        dataKey="_id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} à {last} de {totalRecords}"
        emptyMessage={
        <NoData label="Aucun commande disponible" />
        }
      >
        <Column 
          field="refBc"
          header="BC"
        />
        <Column 
          field="total"
          header="Total"
          body={(order) => `${order.total} DH`}
        />
        <Column 
          header="Statut"
          field="status"
          body={bodyTemplateStatus}
        />
        <Column 
          header="Actions"
          body={(orderData) => {
            return(
              <div className="flex">
              {orderData.status !== "delivered" &&
                <Button 
                  onClick={() => onStatusPressed(orderData, "delivered")}
                  label="livrée" 
                  className="p-button-sm mr-2 no-outline-button"
                  style={{ 
                    backgroundColor: orderData.newStatus === "delivered" ? "#05B171": "#F8F9FA", 
                    borderColor: orderData.newStatus === "delivered" ? "#05B171": "#F8F9FA",
                    color: orderData.newStatus === "delivered" ? "#fff": "#000",
                    outline: 'none', boxShadow: 'none', 
                  }}
                />
              }
              {orderData.status !== "canceled" &&
                <Button 
                  onClick={() => onStatusPressed(orderData, "canceled")}
                  label="annulée" 
                  className="p-button-sm"
                  style={{ 
                    backgroundColor: orderData.newStatus === "canceled" ? "#DC3545": "#F8F9FA", 
                    borderColor: orderData.newStatus === "canceled" ? "#DC3545": "#F8F9FA",
                    color: orderData.newStatus === "canceled" ? "#fff": "#000",
                    outline: 'none', boxShadow: 'none', 
                  }}
                />
              }
              </div>
            )
          }}
        />
      </DataTable>
    </Dialog>
    </>
  )
}

export default OrdersByCustomers;