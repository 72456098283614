import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import NoData from "../components/NoData"
import { ExpeditionsService } from "../service/ExpeditionsService";
import { Calendar } from "primereact/calendar";
import { DeliveryPeopleService } from "../service/DeliveryPeopleService";
import ExpeditionDetails from "../components/expeditions/ExpeditionDetails";
import RangeDateSelect from "../components/RangeDateSelect";
import { DateTime } from "luxon";
import { formatDate } from "../utils/formatDate";
const Expeditions = () => {

  const expeditionsService = new ExpeditionsService();
  const deliveryPeopleService = new DeliveryPeopleService();
  const [expeditions, setExpeditions] = useState({
    records: [],
    count_records: 0,
  });
  const [loading, setLoading] = useState(false);
  const [deliveryPeople, setDeliveryPeople] = useState(null);
  const [loadingDeliveryPeople, setLoadingDeliveryPeople] = useState(false)
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    filters: {
      expedition_id: null,
      name: null,
      dates: null,
      livreur_id: null,
      deliveryMan: null,
      status: null,
      expeditionType: null,
    },
    sortfield: "createdAt",
    sortorder: -1
  });
  const [selectedRow, setSelectedRow] = useState(null)
  const [dialogVisibility, setDialogVisibilty] = useState(false)

  const statusOptions = [
    { label: "Tous", value: null},
    { label: "En cours", value: "pending" },
    { label: "Complété", value: "completed" },
  ]

  const expeditionTypesOptions = [
    { label: "Tous", value: null},
    { label: "espèces", value: "cash" },
    { label: "cachet", value: "cachet" },
  ]

  useEffect(() => {
    getDeliveryPeople()
  },[])

  useEffect(() => {
    lazyLoadData();
  }, [lazyParams]);

  async function lazyLoadData() {
    setLoading(true);
    const response = await expeditionsService.getAll(generateQueryParams());
    if(response.data) {
      setExpeditions(response.data)
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }

  async function getDeliveryPeople(){ 
    setLoadingDeliveryPeople(true)
    const queryParams = `current_page=1&limit=100`
    const response = await deliveryPeopleService.getAll(queryParams);
    if(response.data){
      setDeliveryPeople(response.data.records)
    } else {
      console.log(response.error);
    }
    setLoadingDeliveryPeople(false)
  }

  const generateQueryParams = () => {
    let queryParams = `sortfield=${lazyParams.sortfield}&sortorder=${lazyParams.sortorder}&current_page=${lazyParams.page}&count_records=${expeditions.count_records}&total_skip=${lazyParams.first}`
    if(lazyParams.filters.expedition_id){
      queryParams+=`&expedition_id=${lazyParams.filters.expedition_id}`
    }
    if(lazyParams.filters.name){
      queryParams+=`&name=${lazyParams.filters.name}`
    }
    if(lazyParams.filters.dates){
      if(lazyParams.filters.dates[0]){
        queryParams+=`&from=${formatDate(lazyParams.filters.dates[0])}`
      }
      if(lazyParams.filters.dates[1]){
        queryParams+=`&to=${formatDate(lazyParams.filters.dates[1])}`
      }
    }    
    if(lazyParams.filters.livreur_id){
      queryParams+=`&livreur_id=${lazyParams.filters.livreur_id}`
    }
    if(lazyParams.filters.deliveryMan){
      queryParams+=`&deliveryMan=${lazyParams.filters.deliveryMan}`
    }
    if(lazyParams.filters.status){
      queryParams+=`&status=${lazyParams.filters.status}`
    }
    if(lazyParams.filters.expeditionType){
      queryParams+=`&expeditionType=${lazyParams.filters.expeditionType}`
    }
   
    console.log(queryParams)
    return queryParams
  }

  const setSelectedRange = (value) => {
    console.log(value)
    setLazyParams({
      ...lazyParams,
      first:0,
      rows: 20,
      page: 1,
      filters: {
       ...lazyParams.filters,
        dates: value
      }
    })
  }

  const onPage = (event) => {
    console.log(event)
    setLazyParams({
        ...lazyParams,
        first: event.first,
        rows: event.rows,
        page: event.page + 1
    })
  }

  const onSort = (event) => {
    let sortorder;
    if(lazyParams.sortfield == null || lazyParams.sortfield != event.sortField){
      sortorder = -1
    } else {
      sortorder = lazyParams.sortorder * -1
    }
    setLazyParams({
        ...lazyParams,
        first: 0,
        rows: 20,
        page: 1,
        sortfield: event.sortField,
        sortorder: sortorder,
    });
  };

  const onNameChanged = (event) => {
    console.log(event.target.value)
    setLazyParams({
      ...lazyParams,
      first:0,
      rows: 20,
      page: 1,
      filters: {
       ...lazyParams.filters,
        name: event.target.value
      }
    })
  }

  const onStatusChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      first:0,
      rows: 20,
      page: 1,
      filters: {
       ...lazyParams.filters,
        status: event.value
      }
    })
  }

  const onExpeditionTypeChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      first:0,
      rows: 20,
      page: 1,
      filters: {
       ...lazyParams.filters,
        expeditionType: event.value
      }
    })
  }

  const onDeliveryManChanged = (event) => {
    setLazyParams({
      ...lazyParams,
      first:0,
      rows: 20,
      page: 1,
      filters: {
       ...lazyParams.filters,
        deliveryMan: event.value
      }
    })
  }

  const onRowClicked = (event) => {
    setSelectedRow(event.data)
    setDialogVisibilty(true)
  }

  const nameFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="nom"
        onChange={onNameChanged}
        value={lazyParams.filters.name}
      />
    );
  };
  
  const dateFilter = () => {
    return (
      <RangeDateSelect 
        selectedRange={lazyParams.filters.dates}
        setSelectedRange={setSelectedRange}
      />
    );
  };

  const statusFilter = () => {
    return (
      <Dropdown
        value={lazyParams.filters.status}
        placeholder="Statut"
        options={statusOptions}
        onChange={onStatusChanged}
        className="mr-2 p-column-filter p-inputtext-sm"
      />
    );
  };

  const expeditionTypeFilter = () => {
    return (
      <Dropdown
        value={lazyParams.filters.expeditionType}
        placeholder="Type d'expédition"
        options={expeditionTypesOptions}
        onChange={onExpeditionTypeChanged}
        className="mr-2 p-column-filter p-inputtext-sm"
      />
    );
  };

  const deliveryPeopleFilter = () => {
    return (
      <Dropdown
        showClear
        value={lazyParams.filters.deliveryMan}
        placeholder={loadingDeliveryPeople ? "Loading..." : "Livreurs"}
        options={deliveryPeople}
        optionLabel="fullName"
        optionValue="_id"
        onChange={onDeliveryManChanged}
        disabled={loadingDeliveryPeople}
        className="p-column-filter mr-2 p-inputtext-sm"
      />
    );
  }


  const bodyTemplateStatus = (val) => {
    let severity, text
    if(val.status === "pending") {
      severity = '#FAAE42'
      text = 'En attente' 
    } else if(val.status === "completed") {
      severity = '#05B171'
      text = 'Complétée'
    }
    return(
      <div 
        style={{ 
          backgroundColor: severity,
          borderRadius:12,
          display:'inline-block'
        }} 
        className='pr-2 pl-2'
      >
        <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{text}</p>
      </div>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="mb-4 flex alignItems-center justify-content-between">
          <div className="flex align-items-center justify-content-center">
            <p className="text-2xl font-semibold">Livreurs</p>
          </div>
        </div>

        <div className="card">
          <div className="overflow-x-auto mb-4 flex align-items-center justify-content-start">
            { deliveryPeopleFilter() }
            { dateFilter() }
            { nameFilter() }
            { statusFilter() }
            { expeditionTypeFilter() }
          </div>
          {dialogVisibility && selectedRow &&
            <ExpeditionDetails 
              rowData={selectedRow} dialogVisibility={dialogVisibility} setDialogVisibilty={setDialogVisibilty}
            />
          }
          <DataTable
            onRowClick={onRowClicked}
            paginator
            lazy
            first={lazyParams.first}
            loading={loading}
            rows={20}
            totalRecords={expeditions.count_records}
            onPage={onPage}
            onSort={onSort}
            filterDisplay="row"
            stripedRows
            ref={dt}
            rowHover
            responsiveLayout="scroll"
            value={expeditions.records}
            size="small"
            dataKey="_id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} à {last} de {totalRecords}"
            emptyMessage={<NoData label="Aucun expedition disponible" 
          />}
          >
            <Column
              field="name"
              header="Nom"
            />
            <Column
              sortable
              field="createdAt"
              header="Date"
              body={
                (val) => DateTime
                  .fromISO(val.createdAt)
                  .setZone("Africa/Casablanca")
                  .toFormat("dd-MM-yy HH:mm")
              }
            />
            <Column 
              field="deliveryMan"
              header="Livreur"
              body={(val) => <p>{val.deliveryMan.fullName}</p>}
            />
            <Column 
              field="status"
              header="statut"
              body={bodyTemplateStatus}
            />
            <Column 
              field="expeditionType"
              header="Type d'expédition"
            />
            
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Expeditions;
