import React, { useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import BreadCrump from "../../components/BreadCrump";
import { Button } from "primereact/button";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomersService } from "../../service/CustomersService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cities from "../../utils/Ville_V2.json";
import CustomInput from "../../components/CustomInput";
import CustomSwitch from "../../components/CustomSwitch";
import ChangePassword from "../../components/customers/ChangePassword";
import CustomDropDown from "../../components/CustomDropDown";
import { TypesService } from "../../service/TypesService";

const UpdateCustomer = () => {
  const customerService = new CustomersService();
  const typesService = new TypesService();
  const toast = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { _id } = useParams();

  const [customer, setCustomer] = useState({});

  const getCustomerDetails = async () => {
    const response = await customerService.getCustomerDetail(_id);
    if (response.data) {
      setCustomer(response.data);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur de serveur, réessayez plus tard",
        life: 3000,
      });
      console.log(response.error);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [_id]);

  const {
    rowData: { city },
    types2,
  } = location?.state;

  const {
    ISE,
    nameEntreprise,
    personalName,
    phoneNumber,
    address,
    active,
    customerType,
    customerReference,
    coordinates
  } = customer;

  const initialValues = {
    ice: ISE,
    customerReference: customerReference,
    businessName: nameEntreprise,
    personalName: personalName,
    phone: phoneNumber,
    address: address,
    type: customerType,
    ville: city,
    active: active,
    coordinates: coordinates?.latitude ? `${coordinates.latitude}, ${coordinates.longitude}` : ""
  };

  const validationSchema = Yup.object().shape({
    ice: Yup.string(),
    customerReference: Yup.string(),
    businessName: Yup.string(),
    personalName: Yup.string(),
    phone: Yup.string().max(10).min(10),
    address: Yup.string(),
    type: Yup.string(),
    ville: Yup.string(),
    coordinates: Yup.string()
  });

  const onSubmit = (values, actions) => {
    const {
      ice,
      customerReference,
      businessName,
      personalName,
      phone,
      address,
      type,
      ville,
      coordinates,
      active,
    } = values;
    const data = {
      _id: _id,
      ISE: ice,
      customerReference: customerReference,
      nameEntreprise: businessName,
      personalName: personalName,
      phoneNumber: phone,
      address: address,
      customerType: type,
      city: ville,
      active: active,
      coordinates: {
        latitude: coordinates.split(",")[0],
        longitude: coordinates.split(",")[1],
      }
    };
    _updateCutomer(data, actions);
  };

  const _updateCutomer = async (data, actions) => {
    const response = await customerService.updateCustomer(_id, data);
    if (response.data) {
      navigate("/customers");
    } else {
      actions.setSubmitting(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response.error,
        life: 3000,
      });
      console.log(response.error);
    }
  };

  const breadCrumpItems = {
    href: "customers",
    parentName: "utilisateur",
    currentPage: nameEntreprise,
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column sm:flex-row align-items-center justify-content-between">
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className="card p-fluid grid">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            setSubmitting,
            errors,
            touched,
          }) => {
            const isFormFieldValid = (name) =>
              !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
              return (
                isFormFieldValid(name) && (
                  <small className="p-error">{errors[name]}</small>
                )
              );
            };

            return (
              <>
                <div className="col-12 md:col-6">
                <CustomInput
                    label="nom de l'établissement"
                    name="businessName"
                    placeholder={"nom de l'établissement"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.businessName}
                  />
                  <CustomInput
                    label="nom personel"
                    name="personalName"
                    placeholder={"nom personel"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.personalName}
                  />

                  <CustomInput
                    label="numéro de téléphone"
                    name="phone"
                    keyfilter="pnum"
                    placeholder={"numéro de téléphone"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.phone}
                  />
                  <CustomInput
                    label="coordonnées de localisation"
                    name="coordinates"
                    placeholder={"latitude, longitude"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.coordinates}
                  />                  
                  {/* ICE */}
                  <CustomInput
                    label="ICE"
                    name="ice"
                    placeholder={"ice"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.ice}
                  />

                  {/* REFERENCE */}
                  <CustomInput
                    label="réference de client"
                    name="customerReference"
                    placeholder={"réference"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.customerReference}
                  />
                </div>

                <div className="col-12 md:col-6">
                  {/* ADDRESS */}
                  <CustomInput
                    label="address"
                    name="address"
                    placeholder={"address"}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    value={values.address}
                  />

                  {/* TYPE */}
                  <CustomDropDown
                    label="type d'utilisateur"
                    placeholder={"sélectionnez un type"}
                    name="type"
                    optionValue="value"
                    optionLabel="label"
                    filterBy="value"
                    options={types2}
                    value={values.type}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                  />

                  {/* CITY */}
                  <CustomDropDown
                    label="ville"
                    placeholder={"sélectionnez une ville"}
                    name="ville"
                    optionValue="ville"
                    optionLabel="ville"
                    filterBy="ville"
                    options={Cities}
                    value={values.ville}
                    handleChange={handleChange}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                  />

                  <CustomSwitch
                    label="status"
                    name="active"
                    active={values.active}
                    handleChange={handleChange}
                  />
                </div>

                <Divider />

                <ChangePassword _id={_id} />

                <div className="mb-3 flex justify-content-end w-full">
                  <Button
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    label="modifier"
                    className="w-auto p-button-warning"
                    icon="pi pi-pencil"
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default UpdateCustomer;
